import { memo, useEffect, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import ReactGA from "react-ga4";
import { useAuth } from "react-oidc-context";
import { SESSION_URL } from "config";
import { badgeRef } from "layouts/MainLayout";

const SourceVisitorCode = memo(() => {
    const { user } = useAuth();
    const [time, setTime] = useState<number>();

    const handleSendSessionAndSourceVisitorCode = () => {
        let SourceVisitorCode = localStorage.getItem('source_visitor_code');
        if (!SourceVisitorCode) {
            SourceVisitorCode = uuidv4() as string;
            localStorage.setItem('source_visitor_code', SourceVisitorCode);
        }
        ReactGA.gtag("set", "user_properties", {
            source_visitor_code: SourceVisitorCode,
            user_id: user?.profile.sub as string
        });
        return axios.post(
            SESSION_URL,
            {
                SourceVisitorCode
            },
            {
                headers: {
                    "Authorization": `Bearer ${user?.access_token}`
                }
            }
        ).then((res) => {
            sessionStorage.setItem("session_sent", "true");
            return res;
        })
    }

    useEffect(() => {
        if (!sessionStorage.getItem("session_sent") || !localStorage.getItem('source_visitor_code')) {
            handleSendSessionAndSourceVisitorCode();
        } else {
            const SourceVisitorCode = localStorage.getItem('source_visitor_code');
            ReactGA.gtag("set", "user_properties", {
                source_visitor_code: SourceVisitorCode,
                user_id: user?.profile.sub as string
            });
        }
    }, [user?.access_token])

    useEffect(() => {
        function handleVisibilityChange(this: Document, ev: Event) {
            if (this.visibilityState === "hidden") {
                setTime(Date.now())
            }
            if (this.visibilityState === "visible" && time) {
                // console.log("Total idal", time, Date.now(), (Date.now() - time) / 1000);
                if ((Date.now() - time) >= 5 * 60 * 1000) { // 15 mins tab inactivity session send
                    console.log("You are more than 5 mins idle");
                    handleSendSessionAndSourceVisitorCode().then(() => {
                        badgeRef?.current?.refetch();
                    })
                }
            }
        }

        document.addEventListener<"visibilitychange">('visibilitychange', handleVisibilityChange);
        return () => {
            document.removeEventListener<"visibilitychange">('visibilitychange', handleVisibilityChange);
        };
    }, [time, setTime, user?.access_token, badgeRef?.current]);

    return null;
});

export default SourceVisitorCode;