import { FC, Fragment, Suspense, lazy } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "@emotion/styled";
import HelmetWithNotificationCount from "components/HelmetWithNotificationCount";
import FilterContainer from "./FilterContainer";
import MainLayout, { CenterPanel, RightPanel } from "layouts/MainLayout";

const FilterPostList = lazy(() => import("features/FilterPostList"));

const FilterPosts: FC = () => {
  const [searchParams] = useSearchParams();

  const hashTagMentioned = searchParams.get("hashTagId");
  const userCreatorId = searchParams.get("userId");
  const dollarTagMentioned = searchParams.get("dollarId");
  const clubId = searchParams.get("clubId");

  return (
    <Fragment>
      <MainLayout>
        <Container>
          <HelmetWithNotificationCount
            title={`FilterPosts | StockEdge Social`}
          />
          <FilterContainer
            hashTagId={hashTagMentioned ? hashTagMentioned : ""}
            userId={userCreatorId ? userCreatorId : ""}
            dollarId={dollarTagMentioned ? parseInt(dollarTagMentioned) : undefined}
            clubId={clubId ? clubId : ""}
          />
          <Suspense>
            <FilterPostList
              filter={{
                ...(clubId && { clubId }),
                ...(hashTagMentioned && { hashTagMentioned }),
                ...(dollarTagMentioned && {
                  dollarTagMentioned: parseInt(dollarTagMentioned),
                }),
                ...(userCreatorId && { userCreatorId }),
              }}
            />
          </Suspense>
        </Container>
      </MainLayout>
    
    </Fragment>
  );
};

export default FilterPosts;

const Container = styled.div`
  max-width: 540px;
  margin: auto;
`;
