import { useMediaQuery, styled, useTheme } from "@mui/material";
import { detect } from "detect-browser";
import React, {
  FC,
  lazy,
  PropsWithChildren,
  ReactNode,
  Suspense,
  useState,
} from "react";
import { withAuthenticationRequired } from "react-oidc-context";
import { ProductTourProvider } from "providers/ProductTour";
import NPS from "context/nps";
import { BadgeProvider, BadgeHandle } from "context/badge/BadgeContext";
import { SourceVisitorCode } from "components/SourceVisitorCode";

import CreateAndSharePostProvider from "features/create-and-share-post";
import { CenterPanel } from "./CenterPanel";
import { RightPanel } from "./RightPanel";

export { CenterPanel } from "./CenterPanel";
export { RightPanel } from "./RightPanel";

const Header = lazy(() => import("./components/Header"));
const DefaultMobileHeader = lazy(() => import("./components/MobileHeader"));

const PushNotification = lazy(() => import("./PushNotification"));

const BottomNavigation = lazy(
  () => import("layouts/MainLayout/components/ButtomNavigation")
);

const FloatingButtonMenu = lazy(() =>
  import("features/create-and-share-post").then(({ FloatingButtonMenu }) => ({
    default: FloatingButtonMenu,
  }))
);

const LeftPanel = lazy(() => import("./LeftPanel"));

export const badgeRef = React.createRef<BadgeHandle>();

const isAllowPushNotifications = (): boolean => {
  const browser = detect();
  if (browser?.name === "ios") return false;
  if (browser?.name === "safari") return false;
  if (browser?.name === "crios") return false;
  if (browser?.name === "chromium-webview") return false;
  return true;
};

interface MainLayoutProps {
  mobileHeader?: ReactNode;
  webRightPanel?: ReactNode;
  hideBottomNavigation?: boolean;
}

const MainLayout: FC<PropsWithChildren<MainLayoutProps>> = ({
  children,
  mobileHeader,
  webRightPanel,
  hideBottomNavigation,
}) => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down(960));
  const [openPrompt, setOpenPrompt] = useState(false);

  return (
    <NPS>
      <SourceVisitorCode />
      <ProductTourProvider>
        <CreateAndSharePostProvider>
          <BadgeProvider ref={badgeRef}>
            <Suspense>
              {isMobileOrTablet ? (
                mobileHeader ?? (
                  <DefaultMobileHeader
                    openPrompt={openPrompt as any}
                    setOpenPrompt={setOpenPrompt}
                  />
                )
              ) : (
                <Header
                  openPrompt={openPrompt as any}
                  setOpenPrompt={setOpenPrompt}
                />
              )}
            </Suspense>
            <Main
              sx={{
                maxWidth: "var(--layout-max-width)",
                width: "100%",
                margin: "0 auto",
              }}
            >
              {!isMobileOrTablet && (
                <Suspense>
                  <LeftPanel />
                </Suspense>
              )}
              <CenterPanel>{children}</CenterPanel>
              {!isMobileOrTablet && <RightPanel>{webRightPanel}</RightPanel>}
            </Main>
            {isMobileOrTablet && !hideBottomNavigation && (
              <Suspense>
                <BottomNavigation />
              </Suspense>
            )}
            {isAllowPushNotifications() && (
              <Suspense>
                <PushNotification />
              </Suspense>
            )}
            <Suspense>
              <FloatingButtonMenu />
            </Suspense>
          </BadgeProvider>
        </CreateAndSharePostProvider>
      </ProductTourProvider>
    </NPS>
  );
};

export default withAuthenticationRequired(MainLayout, {
  OnRedirecting: () => <div>Redirecting to the login page...</div>,
});

const Main = styled("main")`
  display: grid;
  grid-template-columns: 1fr;
  @media only screen and (min-width: 960px) {
    grid-column-gap: 10px;
    grid-template-columns:
      var(--side-panel-width) calc(
        100vw - 40px - calc(2 * var(--side-panel-width))
      )
      var(--side-panel-width);
  }
`;
