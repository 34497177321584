import React, { FC } from "react";
import { Tooltip, IconButton, IconButtonProps } from "@mui/material";
import StarOutlineRoundedIcon from "@mui/icons-material/StarOutlineRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import {
  Maybe,
  useMarkClubAsFavouriteMutation,
  useUnmarkClubFavouriteMutation,
} from "api";
import toast from "react-hot-toast";
import { ClubJoinButtonClickedComponentName, sendEvent } from "ga4";

interface ToggleClubFavouriteButtonProps extends IconButtonProps {
  isFavourite: boolean;
  clubId: Maybe<string> | undefined;
  isJoined?: boolean;
  component_name: ClubJoinButtonClickedComponentName;
}

export const ToggleClubFavouriteButton: FC<ToggleClubFavouriteButtonProps> = ({
  isFavourite,
  clubId,
  isJoined,
  component_name,
  ...rest
}) => {
  const [favouriteClub] = useMarkClubAsFavouriteMutation();
  const [removeFavouriteClub] = useUnmarkClubFavouriteMutation();

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();

    event.stopPropagation();
    if (isFavourite) {
      sendEvent({
        event: "club_unfavorite_button_click",
        component_name: component_name,
      });
      removeFavouriteClub({
        variables: { clubId: `${clubId}` },
        update: (cache) => {
          cache.modify({
            fields: {
              getFavouriteClubsOfUser: (old, { readField }) =>
                old?.filter((x) => readField("clubId", x) !== clubId),
            },
          });
        },
        onError: (err) => {
          toast.error(err.message);
        },
      });
    } else {
      sendEvent({
        event: "club_favorite_button_click",
        component_name: component_name,
      });
      favouriteClub({
        variables: { clubId: `${clubId}` },
        update: (cache, { data }) => {
          cache.modify({
            fields: {
              getFavouriteClubsOfUser: (old) => [
                data?.markClubAsFavourite,
                ...old,
              ],
            },
          });
        },
        onError: (err) => {
          toast.error(err.message);
        },
      });
    }
  };

  if (isJoined)
    return (
      <>
        {isFavourite ? (
          <Tooltip title={"Click to remove from favourites"}>
            <IconButton
              {...rest}
              onClick={handleClick}
              sx={{
                color: "#1b6ac9",
              }}
            >
              <StarRoundedIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title={"Click to add to favourites"}>
            <IconButton
              {...rest}
              onClick={handleClick}
              sx={{
                color: "#1b6ac9",
              }}
            >
              <StarOutlineRoundedIcon />
            </IconButton>
          </Tooltip>
        )}
      </>
    );
  return null;
};

export default ToggleClubFavouriteButton;
