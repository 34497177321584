import React, { FC } from "react";
import { range } from "lodash-es";
import styled from "@emotion/styled";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  OutlinedInput,
  Typography,
  Stack,
} from "@mui/material";
import { load } from "recaptcha-v3";
import Modal from "components/Modal";
import { Close } from "@mui/icons-material";
import { Formik, FormikHelpers } from "formik";
import SentimentNeutralIcon from "@mui/icons-material/SentimentNeutral";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import { toast } from "react-hot-toast";
import {
  useAddNpsRatingForWebMutation,
  useIgnoreNpsRatingMutation,
  DeviceType,
  NpsIgnoreSource,
} from "api";
import { RECAPTHA_SITE_KEY } from "config";

import { ReCaptchaBranding } from "components/ReCaptchaBranding";
import { ModalLoader } from "components/ModalLoader";
import { useAuth } from "react-oidc-context";
import { gql } from "@apollo/client";

const MIN = 0,
  MAX = 10;

const getPlatform = () => {
  // check for common mobile user agents
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  ) {
    return DeviceType.Mobileweb;
  }
  return DeviceType.Web;
};

interface NPSModalProps extends DialogProps {
  onhandleClose: () => void;
}

interface FormData {
  rating: number | undefined;
  reason: string;
}

const NPSModal: FC<NPSModalProps> = ({ onhandleClose, ...rest }) => {
  const { user } = useAuth();
  const [addNpsRating, { loading }] = useAddNpsRatingForWebMutation();

  const [ignoreNpsRating, { loading: ignoreNPSLoading }] =
    useIgnoreNpsRatingMutation();

  const handleClose = async (source: NpsIgnoreSource) => {
    try {
      await ignoreNpsRating({
        variables: { source },
        update: (cache, { data }) => {
          if (data?.ignoreNpsRating) {
            cache.updateFragment(
              {
                id: cache.identify({
                  __typename: "User",
                  id: user?.profile?.sub,
                }),
                fragment: gql`
                  fragment NewUser on User {
                    showNpsV2
                  }
                `,
              },
              (data) => ({
                ...data,
                showNpsV2: false,
              })
            );
          }
        },
      });
    } catch (e: any) {
      // toast.error(e?.message);
    } finally {
      onhandleClose();
    }
  };

  const handleSubmit = async (
    values: FormData,
    { setSubmitting, resetForm }: FormikHelpers<FormData>
  ) => {
    try {
      const recaptcha = await load(RECAPTHA_SITE_KEY, {
        autoHideBadge: true,
      });
      const RecaptchaToken = await recaptcha.execute("submit");
      await addNpsRating({
        variables: { ...values, RecaptchaToken, platformType: getPlatform() },
        update: (cache, { data }) => {
          if (data?.addNpsRatingForWeb?.success) {
            cache.updateFragment(
              {
                id: cache.identify({
                  __typename: "User",
                  id: user?.profile?.sub,
                }),
                fragment: gql`
                  fragment NewUser on User {
                    showNpsV2
                  }
                `,
              },
              (data) => ({
                ...data,
                showNpsV2: false,
              })
            );
            toast.success("Thank you for your valuable feedback");
          }
        },
      });
      resetForm();
      onhandleClose();
    } catch (e: any) {
      toast.error(e?.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <CustomModal disableEscapeKeyDown {...rest}>
      <Formik
        initialValues={
          {
            rating: undefined,
            reason: "",
          } as FormData
        }
        onSubmit={handleSubmit}
      >
        {({
          values,
          handleChange,
          handleBlur,
          setFieldValue,
          submitForm,
          isSubmitting,
        }) => (
          <>
            <ModalLoader open={loading || isSubmitting || ignoreNPSLoading} />
            <DialogTitle>
              Share your feedback
              <IconButton onClick={() => handleClose(NpsIgnoreSource.Cross)}>
                <Close />
              </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent>
              <FormLabel variant="subtitle2">
                How likely are you to recommend StockEdge Social to your friends
                and family?
              </FormLabel>
              <RangeContainer>
                {range(MIN, MAX + 1)?.map((i) => (
                  <Range key={i}>
                    <div
                      style={{
                        visibility: i === values.rating ? "visible" : "hidden",
                      }}
                    >
                      {getSentiment(i)}
                    </div>
                    <Item
                      style={
                        i === values.rating
                          ? { background: getColor(values.rating) }
                          : Number(values.rating) >= 0
                          ? {}
                          : { background: getColor(i) }
                      }
                      onClick={() => setFieldValue("rating", i)}
                    >
                      {i}
                    </Item>
                  </Range>
                ))}
              </RangeContainer>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ marginBottom: 1 }}
              >
                <Caption variant="caption" sx={{ color: "red" }}>
                  Not Likely
                </Caption>
                <Caption variant="caption" sx={{ color: "#66ff63" }}>
                  Most Likely
                </Caption>
              </Stack>

              <FormControl
                style={{
                  visibility: Boolean(Number(values?.rating) >= 0)
                    ? "visible"
                    : "hidden",
                }}
                fullWidth
              >
                <FormLabel variant="subtitle2">
                  {getTitle(values.rating)}
                </FormLabel>
                <OutlinedInput
                  name="reason"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.reason}
                />
                <FormHelperText></FormHelperText>
              </FormControl>
            </DialogContent>
            <ReCaptchaBranding />
            <CustomDialogActions>
              <Button
                onClick={() => handleClose(NpsIgnoreSource.TooEarlyToRate)}
                variant="outlined"
                disabled={isSubmitting}
                fullWidth
              >
                Too early to rate
              </Button>
              <Button
                onClick={submitForm}
                variant="contained"
                disabled={values.rating === undefined || isSubmitting}
                fullWidth
              >
                Submit Feedback
              </Button>
            </CustomDialogActions>
          </>
        )}
      </Formik>
    </CustomModal>
  );
};

export default NPSModal;

const CustomDialogActions = styled(DialogActions)({
  flexDirection: "column-reverse",
  gap: 8,
  ".MuiButton-root": {
    margin: 0,
  },
  "@media (min-width: 321px)": {
    flexDirection: "row",
  },
});

const getTitle = (value: number | undefined) => {
  if (value !== 0 && !value) return "";
  if (value >= 9) return "What do you like the most about us?";
  if (value >= 7) return "How can we improve your experience?";
  return "What was missing in your experience?";
};

const getColor = (value: number | undefined) => {
  if (value !== 0 && !value) return "#f2f5fd";
  if (value >= 9) return "#66ff63";
  if (value >= 7) return "#f9e903";
  return "#E61919";
};

const getSentiment = (value: number) => {
  if (value >= 9) return <SentimentSatisfiedAltIcon htmlColor="#66ff63" />;
  if (value >= 7) return <SentimentNeutralIcon htmlColor="#f9e903" />;
  return <SentimentVeryDissatisfiedIcon htmlColor="#E61919" />;
};

const CustomModal = styled(Modal)`
  .MuiPaper-root {
    height: auto;
    max-height: auto;
  }
`;

const FormLabel = styled(Typography)`
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-bottom: 10px;
`;

const Caption = styled(Typography)`
  font-size: 0.75rem;
  line-height: 1rem;
  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
  }
`;

const RangeContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(11, 1fr);
`;

const Item = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #bfb8b8;
  border-right-width: 0;
  cursor: pointer;
  padding: 4px;
`;

const Range = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &:last-child {
    ${Item} {
      border-right-width: 1px;
    }
  }
`;
