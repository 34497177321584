import { Typography } from "@mui/material";
import { Club, DollarTag, Hashtag, User } from "api";
import { FC } from "react";
import BlueTick from "components/BlueTick";
import GreenTick from "components/GreenTick";
import YellowTick from "components/YellowTick";

interface CombinationSearchTitleProps {
  data: User | DollarTag | Hashtag | Club | null | undefined;
}

export const CombinationSearchTitle: FC<CombinationSearchTitleProps> = ({
  data,
}) => {
  switch (data?.__typename) {
    case "User":
      return (
        <Typography variant="subtitle2" sx={{ textAlign: "left" }}>
          {`${data?.firstName} ${data?.lastName}`}
          {data?.isVerified && <BlueTick />}
          {Boolean(data?.greenTick) && <GreenTick />}
          {Boolean(data?.tick3) && <YellowTick />}
        </Typography>
      );
    case "Club":
      return (
        <Typography
          variant="subtitle2"
          sx={{ textAlign: "left" }}
        >{`${data.clubName}`}</Typography>
      );
    case "DollarTag":
      return (
        <Typography
          variant="subtitle2"
          sx={{ textAlign: "left" }}
        >{`${data?.dollarTagName}`}</Typography>
      );
    case "Hashtag":
      return (
        <Typography variant="subtitle2" sx={{ textAlign: "left" }}>
          {`${data?.hashtagName}`}
        </Typography>
      );
    default:
      return null;
  }
};
