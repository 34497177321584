import { FC, Fragment, lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
// import { CustomRoute } from "components/route";

// layouts
import MainLayout from "layouts/MainLayout";
import { NotificationCountProvider } from "providers";
// import SigninRoute from "components/route/SigninRoute";
import FilterPosts from "pages/FilterPosts/FilterPosts";
import BlockedUsers from "pages/settings/blocked-users";

// pages
const Home = lazy(() => import("pages/Home"));
const Search = lazy(() => import("pages/Search"));
const Bookmarks = lazy(() => import("pages/Bookmark"));
const Notifications = lazy(() => import("pages/notifications"));
const Profile = lazy(() => import("pages/profile"));
const Club = lazy(() => import("pages/club"));
const Hashtag = lazy(() => import("pages/Hashtag/Hashtag"));
const Dollar = lazy(() => import("pages/Dollar"));
const PostDetails = lazy(() => import("pages/PostDetails"));
const SharedPost = lazy(() => import("pages/shared-post"));
const Feedback = lazy(() => import("pages/Feedback"));
const TermsAndConditions = lazy(() => import("pages/TermsAndConditions"));
const PrivacyPolicy = lazy(() => import("pages/PrivacyAndPolicy"));
const Help = lazy(() => import("pages/Help"));
const PublicAnalyst = lazy(() => import("pages/public-analyst"));
const Ticket = lazy(() => import("pages/Feedback/Ticket"));
const RaiseNewIssue = lazy(() => import("pages/Feedback/RaiseNewIssue"));
const HelpCentre = lazy(() => import("pages/HelpCentre"));
const Learn = lazy(() => import("pages/Learn"));

const OldPostRedirect = lazy(() => import("pages/OldPostRedirect"));
const OldStockRedirect = lazy(() => import("pages/OldStockRedirect"));
const Settings = lazy(() => import("../pages/settings"));
const SettingsAndSupport = lazy(() => import("../pages/SettingsSupport"));
const LandingPage = lazy(() => import("../pages/LandingPage"));
const LogoutOIDC = lazy(() => import("pages/LogoutOIDC"));
const AuthCallback = lazy(() => import("pages/AuthCallback"));
const CompleteProfile = lazy(() => import("pages/CompleteProfile"));
const DownloadLink = lazy(() => import("../pages/DownloadLink"));
const Plans = lazy(() => import("pages/LandingPage/Plans"));
const Clubs = lazy(() => import("pages/clubs"));
const OnboardingPage = lazy(() => import("pages/OnboardingPage"));

const Discover = lazy(() => import("pages/Discover"));
const StockEdgeAnalysts = lazy(
  () => import("pages/Discover/StockEdgeAnalysts")
);
const MarketExperts = lazy(() => import("pages/Discover/MarketExperts"));
const DomainExperts = lazy(() => import("pages/Discover/DomainExperts"));
const EngagingMembers = lazy(() => import("pages/Discover/EngagingMembers"));
const TrendingStocks = lazy(() => import("pages/Discover/TrendingStocks"));
const TrendingSector = lazy(() => import("pages/Discover/TrendingSector"));
const TrendingTopics = lazy(() => import("pages/Discover/TrendingTopics"));
const SavedSearches = lazy(
  () => import("../components/SavedSearchesFullListPage")
);
const ClubCategory = lazy(() => import("pages/ClubCategory"));

// Past Posts
// const ProfileOldPosts = lazy(() => import("pages/profile/Post/OldPosts"));
const TopicOldPosts = lazy(() => import("pages/Hashtag/posts/OldPosts"));
const DollarTagOldPosts = lazy(() => import("pages/Dollar/posts/OldPosts"));

const StockDetailsLayout = lazy(
  () => import("pages/Dollar/StockDetailsLayout")
);

const StockTagTabs = lazy(() => import("pages/Dollar/StockTagTabs"));

const DollarTagPosts = lazy(() => import("pages/Dollar/DollarTagPosts"));

// Club
const ClubLayout = lazy(() => import("pages/club/ClubLayout"));
const ClubOldPosts = lazy(() => import("pages/club/OldPosts"));
const ClubTabs = lazy(() => import("pages/club/ClubTabs"));
const ClubAbout = lazy(() => import("pages/club/About"));
// const ClubMedia = lazy(() => import("pages/club/Media"));
const ClubPosts = lazy(() => import("pages/club/ClubPosts"));
const ClubStocks = lazy(() => import("pages/club/Stocks"));
const ClubSectors = lazy(() => import("pages/club/Sectors"));
const ClubTopics = lazy(() => import("pages/club/Topics"));
const ClubContextPosts = lazy(
  () => import("pages/club/components/ClubContextPosts")
);

const ClubInfoMobileViewPage = lazy(
  () => import("pages/club/components/ClubInfoMobileViewPage")
);

// Watchlist
const WatchlistLayout = lazy(() => import("pages/Watchlist"));

// Profile
const ProfileLayout = lazy(() => import("pages/profile/ProfileLayout"));
const ProfileTabs = lazy(() => import("pages/profile/ProfileTabs"));
const ProfileOldPosts = lazy(() => import("pages/profile/Post/OldPosts"));
const ProfileActivities = lazy(() => import("pages/profile/Activities"));
const ProfilePosts = lazy(() => import("pages/profile/Post"));
const ProfileContextPosts = lazy(
  () => import("pages/profile/components/ProfileContextPosts")
);
const UnderApprovalPosts = lazy(
  () => import("pages/profile/UnderApprovalPosts")
);
const ApprovalPostDetails = lazy(
  () => import("pages/ApprovalPostDetails/ApprovalPostDetails")
);

const Routes1: FC = () => {
  return (
    <NotificationCountProvider>
      <Routes>
        <Route
        // element={
        //   <Suspense fallback={<Fragment />}>
        //     <MainLayout />
        //   </Suspense>
        // }
        >
          <Route
            path="home"
            element={
              <Suspense fallback={<Fragment />}>
                <Home />
              </Suspense>
            }
          />

          <Route
            path="watchlist/*"
            element={
              <Suspense fallback={<Fragment />}>
                <WatchlistLayout />
              </Suspense>
            }
          />

          <Route
            path="search"
            element={
              <Suspense fallback={<Fragment />}>
                <Search />
              </Suspense>
            }
          />

          <Route
            path="bookmarks"
            element={
              <Suspense fallback={<Fragment />}>
                <Bookmarks />
              </Suspense>
            }
          />
          {/* Discover routes */}
          <Route
            path="explore"
            element={
              <Suspense fallback={<Fragment />}>
                <Discover />
              </Suspense>
            }
          />
          <Route
            path="stockedge-analysts"
            element={
              <Suspense fallback={<Fragment />}>
                <StockEdgeAnalysts />
              </Suspense>
            }
          />
          <Route
            path="market-experts"
            element={
              <Suspense fallback={<Fragment />}>
                <MarketExperts />
              </Suspense>
            }
          />

          <Route
            path="domain-experts"
            element={
              <Suspense fallback={<Fragment />}>
                <DomainExperts />
              </Suspense>
            }
          />
          <Route
            path="engaging-members"
            element={
              <Suspense fallback={<Fragment />}>
                <EngagingMembers />
              </Suspense>
            }
          />

          <Route
            path="saved-searches"
            element={
              <Suspense fallback={<Fragment />}>
                <SavedSearches />
              </Suspense>
            }
          />
          <Route
            path="trending-stocks"
            element={
              <Suspense fallback={<Fragment />}>
                <TrendingStocks />
              </Suspense>
            }
          />

          <Route
            path="trending-sectors"
            element={
              <Suspense fallback={<Fragment />}>
                <TrendingSector />
              </Suspense>
            }
          />

          <Route
            path="trending-topics"
            element={
              <Suspense fallback={<Fragment />}>
                <TrendingTopics />
              </Suspense>
            }
          />

          <Route
            path="notifications"
            element={
              <Suspense fallback={<Fragment />}>
                <Notifications />
              </Suspense>
            }
          />

          <Route
            path="learn"
            element={
              <Suspense fallback={<Fragment />}>
                <Learn />
              </Suspense>
            }
          />

          <Route path="profile">
            <Route
              path=":username/*"
              element={
                <Suspense fallback={<Fragment />}>
                  <ProfileLayout />
                </Suspense>
              }
            >
              <Route
                path="old-posts"
                element={
                  <Suspense>
                    <ProfileOldPosts />
                  </Suspense>
                }
              />
              <Route
                path="*"
                element={
                  <Suspense>
                    <ProfileTabs />
                  </Suspense>
                }
              >
                <Route
                  path="posts"
                  element={
                    <Suspense>
                      <ProfilePosts />
                    </Suspense>
                  }
                />
                <Route
                  path="under-approval-posts"
                  element={
                    <Suspense>
                      <UnderApprovalPosts />
                    </Suspense>
                  }
                />

                <Route
                  path="activities"
                  element={
                    <Suspense>
                      <ProfileActivities />
                    </Suspense>
                  }
                />

                <Route path="*" element={<Navigate to="posts" replace />} />
              </Route>

              <Route path={`topics`}>
                <Route path=":hashTagId/*">
                  <Route
                    path="posts"
                    element={
                      <Suspense fallback={<></>}>
                        <ProfileContextPosts />
                      </Suspense>
                    }
                  />
                </Route>
              </Route>

              <Route path={`stocks`}>
                <Route path=":dollarId/*">
                  <Route
                    path="posts"
                    element={
                      <Suspense fallback={<></>}>
                        <ProfileContextPosts />
                      </Suspense>
                    }
                  />
                </Route>
              </Route>

              <Route path={`sectors`}>
                <Route path=":dollarId/*">
                  <Route
                    path="posts"
                    element={
                      <Suspense fallback={<></>}>
                        <ProfileContextPosts />
                      </Suspense>
                    }
                  />
                </Route>
              </Route>
            </Route>

            <Route path=":username">
              <Route path="under-approval-posts">
                <Route
                  path=":approvalRequestId"
                  element={
                    <Suspense>
                      <ApprovalPostDetails />
                    </Suspense>
                  }
                />
              </Route>
            </Route>
          </Route>

          <Route path="club">
            <Route
              path=":clubId/*"
              element={
                <Suspense fallback={<Fragment />}>
                  <ClubLayout />
                </Suspense>
              }
            >
              <Route
                path="old-posts"
                element={
                  <Suspense>
                    <ClubOldPosts />
                  </Suspense>
                }
              />
              <Route
                path="*"
                element={
                  <Suspense>
                    <ClubPosts />
                  </Suspense>
                }
              >
                <Route
                  path={`posts`}
                  element={
                    <Suspense>
                      <ClubPosts />
                    </Suspense>
                  }
                />
              </Route>
              {/* <Route
                path={`mobile-club-info`}
                element={
                  <Suspense>
                    <ClubInfoMobileViewPage />
                  </Suspense>
                }
              /> */}
              <Route path={`topics`}>
                <Route
                  path="*"
                  element={
                    <Suspense>
                      <ClubTopics />
                    </Suspense>
                  }
                />
                <Route path=":hashTagId/*">
                  <Route
                    path={`posts`}
                    element={
                      <Suspense fallback={<Fragment />}>
                        <ClubContextPosts />
                      </Suspense>
                    }
                  />
                </Route>
              </Route>
              <Route path={`stocks`}>
                <Route
                  path="*"
                  element={
                    <Suspense>
                      <ClubStocks />
                    </Suspense>
                  }
                />
                <Route path=":dollarId/*">
                  <Route
                    path={`posts`}
                    element={
                      <Suspense fallback={<Fragment />}>
                        <ClubContextPosts />
                      </Suspense>
                    }
                  />
                </Route>
              </Route>
              <Route path={`user`}>
                <Route path=":userId/*">
                  <Route
                    path={`posts`}
                    element={
                      <Suspense fallback={<Fragment />}>
                        <ClubContextPosts />
                      </Suspense>
                    }
                  />
                </Route>
              </Route>

              <Route path={`sectors`}>
                <Route
                  path="*"
                  element={
                    <Suspense>
                      <ClubSectors />
                    </Suspense>
                  }
                />
                <Route path=":dollarId/*">
                  <Route
                    path={`posts`}
                    element={
                      <Suspense fallback={<Fragment />}>
                        <ClubContextPosts />
                      </Suspense>
                    }
                  />
                </Route>
              </Route>
              <Route path={`about`} element={<ClubAbout />} />
              <Route path={`*`} element={<Navigate to="posts" replace />} />
            </Route>
          </Route>

          <Route path="category">
            <Route
              path=":clubCategoryId"
              element={
                <Suspense fallback={<Fragment />}>
                  <ClubCategory />
                </Suspense>
              }
            />
          </Route>
          <Route path="hashtag">
            <Route path=":hashtag">
              <Route
                index
                element={
                  <Suspense fallback={<Fragment />}>
                    <Hashtag />
                  </Suspense>
                }
              />
              <Route
                path="old-posts"
                element={
                  <Suspense fallback={<Fragment />}>
                    <TopicOldPosts />
                  </Suspense>
                }
              />
            </Route>
          </Route>

          <Route path="stock">
            <Route path=":slug">
              <Route
                path=":dollarTagId/*"
                element={
                  <Suspense>
                    <StockDetailsLayout />
                  </Suspense>
                }
              />
            </Route>
          </Route>
          <Route path="sector">
            <Route path=":slug">
              <Route path=":dollarTagId/*">
                <Route
                  index
                  element={
                    <Suspense fallback={<Fragment />}>
                      <Dollar />
                    </Suspense>
                  }
                />
                <Route
                  path="old-posts"
                  element={
                    <Suspense fallback={<Fragment />}>
                      <DollarTagOldPosts />
                    </Suspense>
                  }
                />
              </Route>
            </Route>
          </Route>

          <Route path="industry">
            <Route path=":slug">
              <Route path=":dollarTagId/*">
                <Route
                  path="old-posts"
                  element={
                    <Suspense fallback={<Fragment />}>
                      <DollarTagOldPosts />
                    </Suspense>
                  }
                />
                <Route
                  index
                  element={
                    <Suspense fallback={<Fragment />}>
                      <Dollar />
                    </Suspense>
                  }
                />
              </Route>
            </Route>
          </Route>

          <Route path="stocktag">
            <Route path=":slug">
              <Route
                path=":securityId"
                element={
                  <Suspense fallback={<Fragment />}>
                    <OldStockRedirect />
                  </Suspense>
                }
              />
            </Route>
          </Route>
          <Route path="posts/*">
            <Route path=":postId/*">
              <Route
                path="*"
                element={
                  <Suspense fallback={<Fragment />}>
                    <PostDetails />
                  </Suspense>
                }
              />
            </Route>
          </Route>

          <Route path="old-post">
            <Route
              path=":oldPostId"
              element={
                <Suspense fallback={<Fragment />}>
                  <OldPostRedirect />
                </Suspense>
              }
            />
          </Route>

          <Route
            path="clubs/*"
            element={
              <Suspense fallback={<Fragment />}>
                <Clubs />
              </Suspense>
            }
          />

          <Route
            path="/locallogout"
            element={
              <Suspense fallback={<Fragment />}>
                <LogoutOIDC />
              </Suspense>
            }
          />

          <Route
            path="/settings-support"
            element={
              <Suspense fallback={<Fragment />}>
                <SettingsAndSupport />
              </Suspense>
            }
          />

          <Route
            path="/settings"
            element={
              <Suspense fallback={<Fragment />}>
                <Settings />
              </Suspense>
            }
          />

          <Route
            path="/help-centre"
            element={
              <Suspense fallback={<Fragment />}>
                <HelpCentre />
              </Suspense>
            }
          />
          <Route path="feedback">
            <Route
              index
              element={
                <Suspense fallback={<Fragment />}>
                  <Feedback />
                </Suspense>
              }
            />
            <Route
              path="create"
              element={
                <Suspense fallback={<Fragment />}>
                  <RaiseNewIssue />
                </Suspense>
              }
            />
            <Route
              path=":ticketId"
              element={
                <Suspense fallback={<Fragment />}>
                  <Ticket />
                </Suspense>
              }
            />
          </Route>
          <Route
            path="/filter-posts"
            element={
              <Suspense fallback={<Fragment />}>
                <FilterPosts />
              </Suspense>
            }
          />
          <Route path="settings">
            <Route
              path="blocked-users"
              element={
                <Suspense fallback={<Fragment />}>
                  <BlockedUsers />
                </Suspense>
              }
            />
          </Route>
        </Route>

        <Route
          path="onboarding"
          element={
            <Suspense fallback={<Fragment />}>
              <OnboardingPage />
            </Suspense>
          }
        />
        <Route path="/auth/*">
          <Route
            path="callback"
            element={
              <Suspense fallback={<Fragment />}>
                <AuthCallback />
              </Suspense>
            }
          />
          <Route
            path="complete-profile"
            element={
              <Suspense fallback={<Fragment />}>
                <CompleteProfile />
              </Suspense>
            }
          />
        </Route>
        <Route
          path="/plans"
          element={
            <Suspense fallback={<Fragment />}>
              <Plans />
            </Suspense>
          }
        />

        <Route path="analyst">
          <Route
            path=":username"
            element={
              <Suspense fallback={<Fragment />}>
                <PublicAnalyst />
              </Suspense>
            }
          />
        </Route>

        <Route
          path="/terms"
          element={
            <Suspense fallback={<Fragment />}>
              <TermsAndConditions />
            </Suspense>
          }
        />

        <Route
          path="/privacy"
          element={
            <Suspense fallback={<Fragment />}>
              <PrivacyPolicy />
            </Suspense>
          }
        />

        <Route
          path="/migrations"
          element={
            <Suspense fallback={<Fragment />}>
              <Help />
            </Suspense>
          }
        />

        <Route
          path="/download"
          element={
            <Suspense fallback={<Fragment />}>
              <DownloadLink />
            </Suspense>
          }
        />

        <Route
          index
          element={
            <Suspense fallback={<Fragment />}>
              <LandingPage />
            </Suspense>
          }
        />
      </Routes>
    </NotificationCountProvider>
  );
};

export default Routes1;
