import { Club, DollarTag, Hashtag, User } from "api";
import { To } from "react-router-dom";

export const generateLink = (
  item: User | DollarTag | Hashtag | Club | null | undefined
): To => {
  switch (item?.__typename) {
    case "User":
      return {
        pathname: `/profile/${encodeURIComponent(item?.username as string)}`,
      };

    case "Club":
      return { pathname: `/club/${item?.clubId as string}` };

    case "DollarTag":
      return {
        pathname: `/${item?.dollarTagType?.toLowerCase()}/${encodeURIComponent(
          item?.slug as string
        )}/${item?.dollarTagId}`,
      };

    case "Hashtag":
      return {
        pathname: `/hashtag/${encodeURIComponent(item?.hashtagName as string)}`,
      };

    default:
      return "#";
  }
};
