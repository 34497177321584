import { Club, DollarTag, Hashtag, User } from "api";
import { To } from "react-router-dom";

export const generateCombinationLink = (
  items: Array<User | DollarTag | Hashtag | Club | null | undefined>
): To => {
  const searchParams: Record<string, string> = getSearchParams(items);
  return {
    pathname: "/filter-posts",
    search: new URLSearchParams(searchParams).toString(),
  };
};

const getSearchParams = (
  items: Array<User | DollarTag | Hashtag | Club | null | undefined>
) => {
  return items?.reduce(
    (a, c) => ({ ...getSearchParam(c), ...a }),
    {} as Record<string, string>
  );
};

export const getSearchParam = (
  item: User | DollarTag | Hashtag | Club | null | undefined
): Record<string, string> => {
  switch (item?.__typename) {
    case "User":
      return { userId: item?.id as string };

    case "Club":
      return { clubId: item?.clubId as string };

    case "DollarTag":
      return {
        dollarId: item?.dollarTagId?.toString(),
      };

    case "Hashtag":
      return { hashTagId: item?.TagId as string };

    default:
      return {};
  }
};
