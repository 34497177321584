import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import {
  Button,
  List,
  ListProps,
  ListSubheader,
  Paper,
  Stack,
  styled,
  Typography,
  TypographyProps,
} from "@mui/material";
import { FC, PropsWithChildren } from "react";
import { Link, To } from "react-router-dom";

type TrendingListProps = Omit<ListProps, "component"> & {
  title: string;
  seeAllLink?: To;
};

export const TrendingList: FC<PropsWithChildren<TrendingListProps>> = ({
  children,
  title,
  seeAllLink,
}) => {
  return (
    <List
      component={Paper}
      elevation={0}
      sx={{
        backgroundColor: (theme) => theme.background.transparent,
        marginTop: 1,
        border: 0,
      }}
      subheader={
        <ListSubheader
          component={Stack}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            paddingRight: 0,
            backgroundColor: (theme) => theme.background.transparent,
            position: "static",
          }}
        >
          <Title>{title}</Title>
          {seeAllLink ? (
            <Button
              variant="text"
              component={Link}
              to={seeAllLink}
              endIcon={<ArrowRightIcon />}
              sx={{
                fontSize: "12px",
                "& .MuiButton-endIcon": {
                  margin: 0,
                  fontSize: "16px", // Adjust size
                  color: "gray", // Change color
                  transition: "transform 0.3s ease-in-out",
                },
                "&:hover .MuiButton-endIcon": {
                  transform: "translateX(4px)", // Move icon slightly on hover
                  color: "black", // Change color on hover
                },
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              See all
            </Button>
          ) : null}
        </ListSubheader>
      }
    >
      {children}
    </List>
  );
};

const Title = styled((props: TypographyProps) => (
  <Typography variant="h1" {...props} />
))({
  "&&": {
    fontWeight: "bold",
    fontSize: "0.875rem",
  },
});
