import { useGetSavedSearchQuery } from "api";
import SavedSearchCard from "components/SavedSearchCard";
import TrendingList from "components/TrendingList";

const SavedSearches = () => {
  const { data, loading } = useGetSavedSearchQuery({
    variables: {
      offset: 0,
      limit: 10,
    },
  });
  if (!loading && !data?.getSavedSearch?.length) return null;
  return (
    <TrendingList title="Saved Search" seeAllLink="/saved-searches">
      {data?.getSavedSearch?.slice(0, 10)?.map((searchItem) => (
        <SavedSearchCard
          key={searchItem?.searchId}
          searchItem={searchItem}
          hideRemoveButton
        />
      ))}
    </TrendingList>
  );
};

export default SavedSearches;
