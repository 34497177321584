import { ListItemButton, ListItemButtonProps } from "@mui/material";
import { Club, DollarTag, Hashtag, User } from "api";
import { FC, PropsWithChildren, useMemo } from "react";
import { Link, To } from "react-router-dom";
import { generateLink } from "./generate-single-link";
import { generateCombinationLink } from "./generate-combination-link";

type CombinationSearchLinkProps = {
  data: (User | DollarTag | Hashtag | Club | null | undefined)[];
  handleClick?: () => void;
} & Omit<ListItemButtonProps, "component">;

export const CombinationSearchLink: FC<
  PropsWithChildren<CombinationSearchLinkProps>
> = ({ data, handleClick, children, ...rest }) => {
  const to = useMemo<To>(() => {
    const newData = data?.filter(Boolean);
    if (newData?.length === 1) {
      return generateLink(newData?.[0]);
    }
    return generateCombinationLink(newData);
  }, [data]);

  return (
    <ListItemButton component={Link} to={to} dense onClick={handleClick}>
      {children}
    </ListItemButton>
  );
};
