import { IconButton, Tooltip } from "@mui/material";
import { BookmarkRemove, BookmarkAddOutlined } from "@mui/icons-material";
import {
  Maybe,
  useAddToSavedSearchMutation,
  useRemoveFromSavedSearchMutation,
  UserRecentSearchInput,
  UserRecentSearchOutput,
} from "api";
import { FC, useCallback } from "react";
import { sendEvent } from "ga4";

type AddRemoveSavedSearchButtonProps = Pick<
  UserRecentSearchOutput,
  "isSaved" | "searchId"
> & {
  inputForAdd?: Maybe<
    Maybe<UserRecentSearchInput> | Maybe<UserRecentSearchInput>[]
  >;
  removeButtonOnly?: boolean;
};

export const AddRemoveSavedSearchButton: FC<
  AddRemoveSavedSearchButtonProps
> = ({ searchId, isSaved, inputForAdd, removeButtonOnly }) => {
  const [addToSavedSearch] = useAddToSavedSearchMutation();
  const [removeFromSavedSearch] = useRemoveFromSavedSearchMutation();

  const handleRemoveFromSavedSearch = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      sendEvent({
        event: "saved_search_remove_click",
      });
      removeFromSavedSearch({
        variables: {
          searchId,
        },
        update: (cache) => {
          cache.modify({
            fields: {
              getSavedSearch: (previous, { readField }) =>
                previous?.filter((d) => readField("searchId", d) !== searchId),
            },
          });
        },
      });
    },
    [searchId, removeFromSavedSearch]
  );

  const handleaddToSavedSearch = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      sendEvent({
        event: "saved_search_add_click",
      });

      addToSavedSearch({
        variables: {
          data: inputForAdd,
        },
        update: (cache, { data }) => {
          cache.modify({
            fields: {
              getSavedSearch: (previous) => {
                return [data?.addToSavedSearch, ...previous];
              },
            },
          });
        },
      });
    },
    [inputForAdd, addToSavedSearch]
  );

  if (isSaved)
    return (
      <Tooltip title="Remove from saved searches">
        <IconButton
          sx={{ width: "40px", height: "40px", marginLeft: "auto" }}
          onClick={handleRemoveFromSavedSearch}
        >
          <BookmarkRemove fontSize="small" color="error" />
        </IconButton>
      </Tooltip>
    );
  // if (!removeButtonOnly)
  return (
    <Tooltip title="Add to saved searches">
      <IconButton
        sx={{ width: "40px", height: "40px", marginLeft: "auto" }}
        onClick={handleaddToSavedSearch}
      >
        <BookmarkAddOutlined fontSize="small" />
      </IconButton>
    </Tooltip>
  );
  // return null;
};
