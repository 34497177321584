import { ListItemAvatar, ListItemText } from "@mui/material";
import { Maybe, UserRecentSearchOutput } from "api";
import { FC } from "react";
import CombinationSearchAvatar from "components/CombinationSearchAvatar";
import CombinationSearchLink from "components/CombinationSearchLink";
import CombinationSearchTitle from "components/CombinationSearchTitle";
import CombinationSearchSubTitle from "components/CombinationSearchSubTitle";
import { RemoveSavedSearchButton } from "components/Buttons";
import { sendEvent } from "ga4";

interface SavedSearchCardProps {
  searchItem: Maybe<UserRecentSearchOutput> | undefined;
  hideRemoveButton?: boolean;
}

export const SavedSearchCard: FC<SavedSearchCardProps> = ({
  searchItem,
  hideRemoveButton = false,
}) => {
  const [firstElement, ...restElement] = [
    searchItem?.User,
    searchItem?.Dollartag,
    searchItem?.Hashtag,
    searchItem?.Club,
  ].filter(Boolean);

  const handleClick = () => {
    sendEvent({
      event: "saved_search_click",
    });
  };
  return (
    <CombinationSearchLink
      dense
      data={[firstElement, ...restElement]}
      handleClick={handleClick}
    >
      <ListItemAvatar>
        <CombinationSearchAvatar data={firstElement} />
      </ListItemAvatar>
      <ListItemText
        disableTypography
        primary={<CombinationSearchTitle data={firstElement} />}
        secondary={
          <CombinationSearchSubTitle data={[firstElement, ...restElement]} />
        }
      />
      {!hideRemoveButton && (
        <RemoveSavedSearchButton
          searchId={searchItem?.searchId}
          isSaved={searchItem?.isSaved}
        />
      )}
    </CombinationSearchLink>
  );
};
