import { Avatar } from "@mui/material";
import { Club, DollarTag, Hashtag, Maybe, User } from "api";
import { FC } from "react";
import CustomAvatar from "components/Avatar";
import { getIsRingVisible } from "utills";

interface CombinationSearchAvatarProps {
  data: User | DollarTag | Hashtag | Club | null | undefined;
}

export const CombinationSearchAvatar: FC<CombinationSearchAvatarProps> = ({
  data,
}) => {
  switch (data?.__typename) {
    case "User":
      return (
        <CustomAvatar
          src={data?.avatar?.LowResUri}
          alt="avatar"
          hasRing={getIsRingVisible(data?.membershipLevel)}
        />
      );
    case "Club":
      return <Avatar src={data?.avatar?.LowResUri} />;
    case "DollarTag":
      return <Avatar>$</Avatar>;
    case "Hashtag":
      return <Avatar>#</Avatar>;
    default:
      return null;
  }
};
